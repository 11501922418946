import classNames from 'classnames';
import TmdsSwitch from 'component/tmds/TmdsSwitch';
import TmdsTooltipFunction from 'component/tmds/TmdsTooltipFunction';
import TmdsTooltipPolicy from 'component/tmds/TmdsTooltipPolicy';
import { StorageKey } from 'constant/Storage';
import usePaymentPoint from 'hooks/usePaymentPoint';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePopover from 'hooks/usePopover';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as IcoQuestion } from 'resource/images/ico_question.svg';
import s from 'styles/components/point/PointSection.module.scss';
import { setComma } from 'utils/formatter';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

// https://tmobi.atlassian.net/browse/TMAPPM-537
const POPUP_SHOW_PERIODIC_DAY = 7;

const PointSection = () => {
  const {
    isVisible: visibleFunctionTooltip,
    setNowTimestamp,
    visibleCount,
  } = usePopover(StorageKey.POINT_POPOVER, POPUP_SHOW_PERIODIC_DAY);
  const { pointInfo, isActive, activePoint, disabledPoint } = usePaymentPoint();
  const mainLogger = usePmLogger(LogPageId.Main);
  const mainPointLogger = usePmLogger(LogPageId.MainPoint);
  const [visiblePolicyTooltip, setVisiblePolicyTooltip] = useState(false);

  const availablePoint = useMemo(() => pointInfo?.availablePoint || 0, [pointInfo]);
  const availableActive = useMemo(() => isActive && !!availablePoint, [isActive, availablePoint]);
  const visiblePointTooltip = useMemo(
    () => !availableActive && !!availablePoint && visibleFunctionTooltip && visibleCount < 2,
    [availableActive, availablePoint, visibleCount, visibleFunctionTooltip]
  );

  const pointDescription = useMemo(() => {
    // 포인트가 undefined, null, 0인 경우
    if (!availablePoint) {
      return '포인트가 없습니다';
    }
    return '10% 사용';
  }, [availablePoint]);

  const handleClickSwitch = useCallback(
    (nextActive: boolean) => {
      mainLogger.sendClickLog('tap.point', {
        index: nextActive ? 'on' : 'off',
      });
      if (nextActive) {
        activePoint();
        tmapWrapper.makeToast('포인트 사용 설정 되었습니다.');
      } else {
        disabledPoint();
        tmapWrapper.makeToast('포인트 사용 해지 되었습니다.');
      }
    },
    [activePoint, disabledPoint, mainLogger]
  );

  const handleClickDisabledSwitch = useCallback(() => {
    tmapWrapper.makeToast('보유 포인트가 없습니다');
  }, []);

  const handleClosePolicyTooltip = useCallback(() => {
    setVisiblePolicyTooltip(false);
  }, []);

  const handleCloseFunctionTooltip = useCallback(() => {
    setNowTimestamp();
  }, [setNowTimestamp]);

  const handleClickMyPoint = useCallback(() => {
    mainPointLogger.sendClickLog('tap.point_infobox_mypoint');
    tmapWrapper.openPayPoint();
  }, [mainPointLogger]);

  useEffect(() => {
    if (visibleFunctionTooltip) {
      window.addEventListener('click', handleCloseFunctionTooltip);
    }

    return () => {
      window.removeEventListener('click', handleCloseFunctionTooltip);
    };
  }, [handleCloseFunctionTooltip, visibleFunctionTooltip]);

  useEffect(() => {
    if (visiblePolicyTooltip) {
      window.addEventListener('click', handleClosePolicyTooltip);
    }
    return () => {
      window.removeEventListener('click', handleClosePolicyTooltip);
    };
  }, [handleClosePolicyTooltip, visiblePolicyTooltip]);

  return (
    <div className={s.wrapper}>
      <div className={s.top_content}>
        <div className={s.title}>
          <span className={s.title_text}>포인트</span>
          <TmdsTooltipPolicy
            visible={visiblePolicyTooltip}
            onClose={handleClosePolicyTooltip}
            title={
              <div>
                <div>바이크 이용 시 10%까지</div>
                <div>포인트 사용이 가능합니다.</div>
              </div>
            }
            subText={
              <ul className={s.text_list}>
                <li className={s.list_item}>포인트는 이용전 ON/OFF로 설정이 가능합니다.</li>
                <li className={s.list_item}>
                  소유한 포인트가 부족한 경우 보유한 포인트만 결제에 포함 됩니다.
                </li>
                <li className={s.list_item}>
                  포인트는 이용 하신 후 결제 시, 기기에 따라 금액이 자동 측정되어 적용됩니다.
                </li>
                <li className={s.list_item}>
                  포인트 적용 전 결제금액이 120원 미만인 경우 포인트 적용이 불가합니다.
                </li>
                <li className={s.list_item}>
                  <span
                    className={s.link}
                    onClick={handleClickMyPoint}
                  >
                    포인트 적립 및 사용 내역 {'>'}
                  </span>
                </li>
              </ul>
            }
            placement="top"
          >
            <IcoQuestion
              onClick={(e) => {
                e.stopPropagation();
                setVisiblePolicyTooltip((prev) => !prev);
                mainLogger.sendClickLog('tap.point_infobox');
              }}
            />
          </TmdsTooltipPolicy>
        </div>
        <TmdsTooltipFunction
          className={s.tooltip}
          visible={visiblePointTooltip}
          title={
            <div>
              <div>사용가능한 포인트가 있습니다.</div>
              <div>바이크 이용 시 사용해 보세요.</div>
            </div>
          }
          placement="topRight"
        >
          <div className={s.controller}>
            <span
              className={classNames(s.description, {
                [s.active]: availableActive,
              })}
            >
              {pointDescription}
            </span>
            <TmdsSwitch
              size="small"
              disabled={!availablePoint}
              onDisabledClick={handleClickDisabledSwitch}
              checked={availableActive}
              onClick={() => handleClickSwitch(!isActive)}
              title={availableActive ? '포인트 사용 ON' : '포인트 사용 OFF'}
            />
          </div>
        </TmdsTooltipFunction>
      </div>
      <div className={s.take_point}>
        보유<span className={s.point}>{setComma(availablePoint)} P</span>
      </div>
    </div>
  );
};

export default PointSection;
