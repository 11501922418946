import MobileLogger from '@tmap-web-lib/mobile-logger';
import { isProdEnv } from 'constant/Env';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'styles/index.scss';
import { copyClipboard } from 'utils/clipboard';
import 'utils/datadog';
import { pageCaseMap } from 'utils/mock/msw';

import App from './App';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

async function enableMocking() {
  if (isProdEnv) {
    return;
  }

  const { worker } = await import('./tmsw/browser');

  MobileLogger.render({
    onClickCopy: copyClipboard,
    network: {
      worker: worker as any,
      enabled: true,
      caseMap: pageCaseMap,
    },
  });
}

if (!isProdEnv) {
  MobileLogger.addLog({
    type: 'DEB',
    title: 'serviceWorker 여부',
    message:
      'serviceWorker' in navigator === true ? '서비스워커 사용가능' : '서비스워커 사용불가능',
  });
}

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
});
