import Image from 'component/Image';
import GuideItem from 'component/guide/GuideItem';
import ImageIconX from 'resource/images/ico_x.png';
import { ReactComponent as IconWarning1 } from 'resource/images/icon-guide-2-2-1.svg';
import { ReactComponent as IconWarning2 } from 'resource/images/icon-guide-2-2-2.svg';
import { ReactComponent as IconWarning3 } from 'resource/images/icon-guide-2-2-3.svg';
import { ReactComponent as IconWarning4 } from 'resource/images/icon-guide-2-2-4.svg';
import s from 'styles/components/guide/Guide.module.scss';
import { EGuideAnchor } from 'types/App';

import GuideDescription from './GuideDescription';
import GuideTitle from './GuideTitle';

const GuideDriving = () => (
  <div className={s.guide_wrap}>
    <GuideTitle>대여&주행 방법</GuideTitle>
    <div style={{ marginBottom: '50px' }}>
      <GuideDescription
        title="바이크의 배터리를 확인하세요"
        description="기기의 핀을 눌러 주행 가능한 구역을 확인하실 때 기기의 배터리도 꼭 확인하세요."
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/battery.png"
          />
        }
      />
      <GuideDescription
        title="바이크의 QR코드를 스캔 하세요"
        description={
          <>
            <div>바이크 홈에서 [대여하기] 버튼을 누르고,</div>
            <div>기기의 QR코드를 스캔하세요.</div>
          </>
        }
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/unlock.png"
          />
        }
      />
      <GuideDescription
        title="앉기 편한 높이로 안장을 조절하세요"
        description="전기자전거의 안장 높이를 조절할 수 있어요"
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/convenience_1.png"
          />
        }
      />
      <GuideDescription
        title="페달 밟는 게 더는 힘들지 않아요"
        description={
          <>
            <div>페달을 밟아보세요.</div>
            <div>전기 모터가 작동하여 편안하게 이용 할 수 있어요.</div>
          </>
        }
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/convenience_3.png"
          />
        }
      />
    </div>

    <div className={s.guide_item}>
      <h3
        className={s.title}
        data-anchor={EGuideAnchor.DRIVING_WARNING}
      >
        주행 시 유의사항
      </h3>

      <GuideItem
        list={[
          {
            SvgComponent: <IconWarning1 />,
            title: '서비스 외 지역 확인',
            desc: (
              <>
                지도에 어둡게 표시된 지역은 <br />
                주행 불가 또는 반납 추가 요금이 <br />
                부과 될 수 있어요.
              </>
            ),
          },
          {
            SvgComponent: <IconWarning2 />,
            title: '헬멧 착용은 필수',
            desc: (
              <>
                안전을 위해서 헬멧은
                <br />꼭 착용 해주세요.
              </>
            ),
          },
          {
            SvgComponent: (
              <div className={s.icon_wrap}>
                <IconWarning3 />
              </div>
            ),
            title: '음주 후 탑승 NO!',
            desc: (
              <>
                음주운전은 어느 상황에서도 절대 금지됩니다. 음주운전으로 인한 사고는 보험 적용이
                불가하니 주의해주세요!
              </>
            ),
          },
          {
            SvgComponent: (
              <div className={s.icon_wrap}>
                <IconWarning4 />
              </div>
            ),
            title: '2인 이상 동반 탑승 NO!',
            desc: (
              <>
                안전을 위해
                <br />
                회원 본인만 탑승하세요.
              </>
            ),
          },
        ]}
      />
    </div>
  </div>
);

export default GuideDriving;
