import BackButton from 'component/BackButton';
import { Header } from 'component/Header';
import Tab from 'component/Tab';
import GuideBeforeUse from 'component/guide/GuideBeforeUse';
import GuideDriving from 'component/guide/GuideDriving';
import GuideReport from 'component/guide/GuideReport';
import GuideReturn from 'component/guide/GuideReturn';
import { TActionId } from 'constant/PmLog';
import { Paths } from 'constant/RoutePath';
import useAdditionalLocationSearch from 'hooks/useAdditionalLocationSearch';
import { useInitLogger } from 'hooks/useInitLogger';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmPageLaunch from 'hooks/usePmPageLaunch';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import s from 'styles/pages/PmGuidePage.module.scss';
import { EGuideTab } from 'types/App';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const TAB_LIST = [
  {
    type: EGuideTab.BEFORE_USE,
    title: '이용전',
    Component: GuideBeforeUse,
    className: s.item_beforeuse,
  },
  {
    type: EGuideTab.USING,
    title: '이용중',
    Component: GuideDriving,
    className: s.item_using,
  },
  {
    type: EGuideTab.RETURN,
    title: '반납·결제',
    Component: GuideReturn,
    className: s.item_return,
  },
  {
    type: EGuideTab.REPORT,
    title: '사고·고장신고',
    Component: GuideReport,
    className: s.item_report,
  },
];

const Log: Record<EGuideTab, TActionId> = {
  [EGuideTab.BEFORE_USE]: 'tap.tab_beforeride',
  [EGuideTab.USING]: 'tap.tab_riding',
  [EGuideTab.RETURN]: 'tap.tab_endride',
  [EGuideTab.REPORT]: 'tap.tab_ask',
};

const GUIDE_HEADER_HEIGHT = 56;
const TAB_HEIGHT = 46;

const PmGuidePage = () => {
  const { startInitAction, accessKey } = usePmPageLaunch();
  const initLogger = useInitLogger(startInitAction ? accessKey : '');
  const userGuideLogger = usePmLogger(LogPageId.UserGuide, initLogger);
  const history = useHistory();
  const { currentQuery } = useAdditionalLocationSearch();
  const activeType = useMemo(() => {
    const type = currentQuery?.type as EGuideTab;
    const validQuery = Object.values(EGuideTab).find((v) => v === type) as EGuideTab;

    return validQuery || EGuideTab.BEFORE_USE;
  }, [currentQuery]);

  const refPage = useRef<HTMLDivElement>(null);

  const GuideComponent = useMemo(() => {
    const item = TAB_LIST.find((t) => t.type === activeType);
    return item?.Component || TAB_LIST[0].Component;
  }, [activeType]);

  const updateQuery = useCallback(
    (type, anchor?: string) => {
      history.replace(`${Paths.PmGuide}?type=${type}${anchor ? `&anchor=${anchor}` : ''}`);
    },
    [history]
  );

  const checkAllImageLoad = useCallback(() => {
    if (!refPage.current) {
      return;
    }
    const imgNodeList = refPage.current.querySelectorAll('img');
    const imgArray: HTMLImageElement[] = [];
    imgNodeList.forEach((img) => imgArray.push(img));

    const promises = imgArray.map(
      (it) =>
        new Promise((resolve) => {
          it.onload = () => resolve('');
        })
    );
    return Promise.all(promises);
  }, []);

  const moveToAnchor = useCallback(async () => {
    if (!refPage.current) {
      return;
    }

    const anchorEl = refPage.current.querySelector(
      `[data-anchor="${currentQuery.anchor}"]`
    ) as HTMLElement;

    await checkAllImageLoad();

    const scrollTop = anchorEl?.offsetTop || 0;
    refPage.current?.scroll({
      top: scrollTop - (GUIDE_HEADER_HEIGHT + TAB_HEIGHT),
      behavior: 'smooth',
    });
  }, [checkAllImageLoad, currentQuery.anchor]);

  useEffect(() => {
    if (document.readyState === 'complete') {
      moveToAnchor();
    } else {
      const handleOnLoad = () => {
        moveToAnchor();
        window.removeEventListener('load', handleOnLoad);
      };
      window.addEventListener('load', handleOnLoad);
    }
  }, [moveToAnchor]);

  useEffect(() => {
    updateQuery(activeType, currentQuery?.anchor as string);
  }, []);

  useEffect(() => {
    return () => {
      userGuideLogger.sendClickLog('tap.back');
    };
  }, [userGuideLogger]);

  return (
    <div
      className={s.page_wrap}
      ref={refPage}
    >
      <div className={s.header}>
        <Header
          title="이용 가이드"
          leftButton={
            <BackButton
              onClick={() => {
                userGuideLogger.sendClickLog('tap.back');
                tmapWrapper.closeWebview();
              }}
            />
          }
        />
        <Tab
          list={TAB_LIST}
          activeType={activeType}
          onClick={(_, type) => {
            updateQuery(type);
            userGuideLogger.sendClickLog(Log[type]);
          }}
        />
      </div>
      <div className={s.content_wrap}>
        <GuideComponent onUpdateQuery={updateQuery} />
      </div>
    </div>
  );
};

export default PmGuidePage;
