import classNames from 'classnames';
import Popover from 'component/Popover';
import SideMenuButton from 'component/SideMenuButton';
import PmHeaderDropDown from 'component/pmMain/PmHeaderDropdown';
import useMainHeaderPopover from 'hooks/useMainHeaderPopover';
import usePmApi from 'hooks/usePmStore';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import { useEffect } from 'react';
import { ReactComponent as IcoArrowLeft } from 'resource/images/ico_arrow_left.svg';
import { ReactComponent as IconHome } from 'resource/images/icon-home.svg';
import s from 'styles/components/pmMain/PmMainHeader.module.scss';

type TProps = {
  onClickBack?: VoidFunction;
  onClickSide?: VoidFunction;
  onOpenDropDown?: VoidFunction;
};

const PmMainHeader = ({ onClickBack, onClickSide, onOpenDropDown }: TProps) => {
  const { getVehicleList } = usePmApi();

  const { isRiding } = usePmUsingStatus();

  const { isVisible: isPopoverVisible, text: popoverText } = useMainHeaderPopover();

  useEffect(() => {
    if (!isRiding) {
      getVehicleList();
    }
  }, [isRiding, getVehicleList]);

  return (
    <>
      <div
        className={classNames(s.round_button, s.left)}
        onClick={onClickBack}
      >
        <button className={s.back_button}>
          {isRiding ? (
            <IconHome aria-label="홈" />
          ) : (
            <IcoArrowLeft
              className={s.icon_back}
              aria-label="뒤로가기"
            />
          )}
        </button>
      </div>
      <div
        className={classNames(s.round_button, s.right)}
        onClick={onClickSide}
        aria-label="메뉴"
      >
        <SideMenuButton />
      </div>

      {!isRiding && (
        <div className={s.button_wrap}>
          <button
            className={s.list_button}
            data-cy="company-select"
            aria-label="제조사 선택"
          >
            <PmHeaderDropDown />

            {isPopoverVisible && popoverText && (
              <Popover
                text={popoverText}
                className={s.popover}
              />
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default PmMainHeader;
