import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import s from 'styles/components/DescriptionList.module.scss';

type TProps = {
  list: ReactNode[];
  itemClassName?: string;
  roundDot?: boolean;
  showDot?: boolean;
  gap?: number;
};

const DescriptionList = ({ roundDot, list, itemClassName, showDot = true, gap = 10 }: TProps) => {
  return (
    <ul
      className={s.list_wrap}
      style={
        {
          '--gap': `${gap}px`,
        } as CSSProperties
      }
    >
      {list.map((v, i) => (
        <li
          key={`desc-${i}`}
          className={classNames(s.item, itemClassName)}
        >
          {showDot && (
            <div
              className={classNames(s.dot, {
                [s.is_round]: roundDot,
              })}
            />
          )}
          <span className={s.text}>{v}</span>
        </li>
      ))}
    </ul>
  );
};

export default DescriptionList;
