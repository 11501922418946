import useOpenPage from 'hooks/useOpenPage';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import { HTMLAttributes } from 'react';
import { ReactComponent as IcoMemo } from 'resource/images/ico_memo.svg';

type TProps = HTMLAttributes<HTMLButtonElement>;

export const PmGuideButton = ({ ...buttonProps }: TProps) => {
  const { openGuidePage } = useOpenPage();
  const mainLogger = usePmLogger(LogPageId.Main);
  const ridingLogger = usePmLogger(LogPageId.Riding);
  const { isRiding } = usePmUsingStatus();

  return (
    <button
      {...buttonProps}
      onClick={() => {
        openGuidePage();
        isRiding ? ridingLogger.sendClickLog('tap.guide') : mainLogger.sendClickLog('tap.guide');
      }}
    >
      <IcoMemo />
      <span>이용가이드</span>
    </button>
  );
};

export default PmGuideButton;
