import classNames from 'classnames';
import { COMPANY_ICON_MAP, COMPANY_NAME_MAP } from 'constant/Scooter';
import s from 'styles/components/pmCode/PmCompanyButton.module.scss';
import { ECompanyType } from 'types/App';

const PmCompanyButton = () => (
  <button
    className={s.select_button}
    data-cy="회사선택"
  >
    <i className={classNames(s.icon, s.icon_company)}>{COMPANY_ICON_MAP[ECompanyType.TMAP]}</i>
    <span className={classNames(s.text)}>{COMPANY_NAME_MAP[ECompanyType.TMAP]}</span>
  </button>
);

export default PmCompanyButton;
