import { API_VERSION_HEADER } from 'constant/Api';
import { TTripStartResponse } from 'types/App';
import { TLonLat } from 'types/Map';
import {
  TReturnCameraParam,
  TTripEndResponse,
  TTripOrderIdResponse,
  TTripStartParam,
} from 'types/api/Trip';
import { TUserTripStatus } from 'types/api/User';
import { getApi, postApi } from 'utils/fetcher';

const TRIP_API_TIMEOUT = 60 * 1000;

class TripRepository {
  getTripStatus = (tripId: number) => {
    return getApi<Partial<TUserTripStatus>>(`/api/trip/${tripId}/status`);
  };

  postTripStart = async (params: TTripStartParam) => {
    const result = await postApi<TTripStartResponse>(
      '/api/trip/start',
      {
        lng: 126.95394442451509,
        lat: 37.55745869012107,
        company: params.company,
        vehicleId: params.vehicleId,
        userOs: params.userOs,
        orderId: params.orderId,
        usePoint: params.usePoint,
        event: params.event,
      },
      {
        timeout: TRIP_API_TIMEOUT,
      }
    );
    return result;
  };

  postUploadTripEndImage = (tripId: number, params: Omit<TReturnCameraParam, 'status'>) => {
    return postApi(`/api/trip/${tripId}/end/base64Image`, params);
  };

  postTripEnd = (tripId: number, lonLat: TLonLat) => {
    return postApi<TTripEndResponse>(
      `/api/trip/${tripId}/end`,
      {
        lng: lonLat.lon,
        lat: lonLat.lat,
      },
      {
        timeout: TRIP_API_TIMEOUT,
        headers: {
          Accept: API_VERSION_HEADER.V2,
        },
      }
    );
  };

  postTripOrderId = () => {
    return postApi<TTripOrderIdResponse>('/api/trip/orderId');
  };

  postTripResume = (tripId: number) => {
    return postApi(`/api/trip/${tripId}/resume`);
  };

  postTripPause = (tripId: number) => {
    return postApi(`/api/trip/${tripId}/pause`);
  };
}

const tripRepository = new TripRepository();

export default tripRepository;
