import classNames from 'classnames';
import DescriptionList from 'component/DescriptionList';
import ListableText from 'component/ListableText';
import ItemGuide from 'component/guide/GuideItem';
import { ReactComponent as IcoPicture } from 'resource/images/ico_guide_3_2.svg';
import { ReactComponent as IcoParkingGuide } from 'resource/images/ico_guide_parking.svg';
import { ReactComponent as IcoParking } from 'resource/images/ico_parking.svg';
import { ReactComponent as IcoPayComplete } from 'resource/images/ico_pay_complete.svg';
import { ReactComponent as IcoSirenRed } from 'resource/images/ico_siren_red.svg';
import s from 'styles/components/guide/Guide.module.scss';
import guideStyle from 'styles/components/guide/GuideReturn.module.scss';
import { EGuideAnchor } from 'types/App';

import GuideDescription from './GuideDescription';
import GuideTitle from './GuideTitle';

const GuideReturn = () => (
  <div className={s.guide_wrap}>
    <GuideTitle>반납 방법</GuideTitle>
    <div className={classNames(s.guide_item, guideStyle.first_wrapper)}>
      <ItemGuide
        list={[
          {
            SvgComponent: <IcoParkingGuide />,
            title: '안전하게 주차하기!',
            desc: '주변에 피해를 주지 않는 곳에 주차해 주세요.',
          },
          {
            SvgComponent: <IcoPicture />,
            title: '반납 사진 찍기',
            desc: '반납 요청 후 주차사진을 촬영해 반납을 완료합니다.',
          },
        ]}
      />
      <div className={guideStyle.warning_wrapper}>
        <div className={guideStyle.warning_title}>주차 사진은 꼭 찍어주세요 📸</div>
        <DescriptionList
          list={[
            '견인/보관료는 마지막 사용자에게 부과됩니다.',
            '부당한 요금 징수 소명을 위해 주차사진을 증거로 사용합니다.​',
          ]}
          gap={2}
        />
      </div>

      <ItemGuide
        list={[
          {
            SvgComponent: <IcoPayComplete />,
            title: '결제 완료!',
            desc: '이용요금은 등록하신 티맵 결제수단으로 자동 결제됩니다.',
          },
        ]}
      />
    </div>

    <div className={guideStyle.sub_title}>
      <IcoParking />
      <span>이렇게 주차해 주세요!</span>
    </div>

    <div className={guideStyle.best_case_wrapper}>
      <GuideDescription
        title="보도 내, 건물 옆 등"
        description="보도 측면 펜스나 화단, 가로수 등 보행자를 위해 사람이 많이 다니지 않는 곳에 주차해 주세요"
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/parking_road.png"
          />
        }
      />
      <GuideDescription
        title="자전거 거치대 주변, 이륜차 주차장 주변"
        description="자전거 거치대 주변이나 이륜차 주차장 주변에 주차해 주세요"
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/parking_zone.png"
          />
        }
      />
    </div>

    <div
      className={guideStyle.sub_title}
      data-anchor={EGuideAnchor.RETURN_WARNING}
    >
      <IcoSirenRed />
      <span>이런곳에 주차하시면 안돼요</span>
    </div>
    <div className={guideStyle.warning_wrapper}>
      <div className={guideStyle.warning_title}>
        <span className={guideStyle.danger}>견인/보관료(4~5만원)가 징수</span>될 수 있어요
      </div>
      <DescriptionList
        showDot={false}
        list={[
          '올바르게 주차하지 않을 경우 추가요금 또는 지자체 정책에 의해 견인/보관료가 징수될 수 있어요',
        ]}
      />
    </div>
    <div className={guideStyle.worst_case_wrapper}>
      <GuideDescription
        title="버스/택시 정류장 10미터 이내"
        description={
          <>
            <div>혼잡한 구역입니다.</div>
            <div>다른 분들의 통행을 방해하지 마세요.</div>
          </>
        }
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/worst_bus_stop.png"
          />
        }
      />
      <GuideDescription
        title="지하철역 출구 앞, 엘리베이터 앞"
        description={
          <>
            <div>혼잡한 구역입니다.</div>
            <div>다른 분들의 통행을 방해하지 마세요.</div>
          </>
        }
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/worst_subway.png"
          />
        }
      />
      <GuideDescription
        title="차도 위, 자전거 도로 위"
        description={
          <>
            <div>보도블럭 아래 주차는 피해주세요.</div>
            교통 사고의 위험이 높습니다.
          </>
        }
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/worst_road.png"
          />
        }
      />
      <GuideDescription
        title="인도 한가운데, 횡단보도 위, 점자 보도블럭 위"
        description="사고의 위험이 있으니 인도, 횡단보도, 점자 보도블럭 등에서 다른 분들의 통행을 방해하지 마세요."
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/worst_cross_road.png"
          />
        }
      />
      <GuideDescription
        title="어린이 놀이터"
        description={
          <>
            <div>어린이들이 다칠 수 있습니다.</div>
            놀이터에 주차하지 마세요.
          </>
        }
        svgComponent={
          <img
            alt="이용 이미지"
            src="/images/worst_play_ground.png"
          />
        }
      />
    </div>
    <ListableText wrapperClassName={guideStyle.footer_description}>
      견인/보관료 징수는 지자체 교통과 정책으로 견인사유 및 비용은 이용지역 교통과에 문의주시기
      바랍니다.
    </ListableText>
  </div>
);

export default GuideReturn;
