import classNames from 'classnames';
import { ReactNode } from 'react';

import s from './ListableText.module.scss';

type Props = {
  wrapperClassName?: string;
  children?: ReactNode;
};

const ListableText = ({ wrapperClassName, children }: Props) => {
  return (
    <div className={classNames(s.wrapper, wrapperClassName)}>
      <div className={s.dot_wrapper}>
        <div className={s.dot}></div>
      </div>
      <span className={s.text}>{children}</span>
    </div>
  );
};

export default ListableText;
