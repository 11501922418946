import { ReactNode } from 'react';

import s from './GuideDescription.module.scss';

type Props = {
  title: ReactNode;
  description: ReactNode;
  svgComponent?: ReactNode;
};

const GuideDescription = ({ title, description, svgComponent }: Props) => {
  return (
    <div className={s.wrapper}>
      <div className={s.title}>{title}</div>
      <div className={s.description}>{description}</div>
      <div className={s.image}>{svgComponent}</div>
    </div>
  );
};

export default GuideDescription;
