import classNames from 'classnames';
import { BASE_COMPANY_LIST } from 'constant/Scooter';
import useActiveCompanyList from 'hooks/useActiveCompanyList';
import usePmStore from 'hooks/usePmStore';
import { ReactComponent as IcoCheckOff } from 'resource/images/ico_check_off.svg';
import { ReactComponent as IcoCheckOn } from 'resource/images/ico_check_on.svg';
import pmStore from 'store/pmStore';
import s from 'styles/components/pmMain/PmHeaderDropdown.module.scss';
import { ECompanyType } from 'types/App';

const COMPANY_BORDER_ICON_MAP = BASE_COMPANY_LIST.reduce(
  (obj, v) => ({ ...obj, [v.code]: v.imgIcon }),
  {}
);

const PmHeaderDropDown = () => {
  const { activeCompany } = usePmStore();

  const companyList = useActiveCompanyList();

  return (
    <div
      className={s.wrap}
      onClick={() => pmStore.setActiveCompany(activeCompany ? undefined : ECompanyType.TMAP)}
    >
      <ul className={s.list}>
        {companyList.map((c, i) => (
          <li
            key={c.code}
            className={classNames(s.item, s.border)}
            style={{ zIndex: companyList.length + i }}
          >
            {COMPANY_BORDER_ICON_MAP[c.code]}
          </li>
        ))}
      </ul>
      <div className={s.text}>이용 구역 보기</div>
      <i className={s.icon_check}>{activeCompany ? <IcoCheckOn /> : <IcoCheckOff />}</i>
    </div>
  );
};

export default PmHeaderDropDown;
