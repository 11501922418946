import { ReactNode } from 'react';

import s from './GuideTitle.module.scss';

type Props = {
  children?: ReactNode;
};

const GuideTitle = ({ children }: Props) => {
  return <div className={s.title}>{children}</div>;
};

export default GuideTitle;
